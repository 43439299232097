import React from 'react';
import Header from './header';
import Termos from './termos';
import { connect } from 'react-redux';
import { changeState, logout } from '../actions/AppActions';
import { DEBUG, URL } from '../variables';
import ChangeCamModal from './changeCamModal';
import ConexaoProcesso from './conexao_processo';
import NotProcesso from './NotProcesso';
// import io from 'socket.io-client';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ProcessoUser from './ProcessoUser';
import moment from 'moment-timezone';
import JoditEditor from 'jodit-react';

// import * as faceapi from 'face-api.js'
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
const OtherRoute = ({ component: Component, path_name, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
    }
  />
);
class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // redirect:'/not_processo',
      other_window: false,
      invalid: false,
      mobile: false,
      screenExtended: false,
      hide:false,
      processos:[],
      show_proccess:false
    }
    this.videoRef = React.createRef();
    this.screenRef = React.createRef();

    this.imageRef = React.createRef();
    this.canvas = React.createRef();
    this.user_id = uuidv4();
    this.audioContext = {};
    this.first = true;
    this.timer_audio_high = Date.now() / 1000
    // this.videoRef=null;
  }
  logout() {
    localStorage.removeItem('token');
    // this.setState({redirect:true,path:'/'});
    this.props.logout();
  }

  draw_peer() {
    try {
      if (this.audioContext['context'].state != 'running') {
        this.audioContext['context'].resume();
      }
      if (
        this.props.stream != null
      ) {


        // var drawVisual = requestAnimationFrame(()=>draw_peer(id));
        this.audioContext['analyser'].getByteTimeDomainData(this.audioContext['dataArray']);

        var sliceWidth = 300 * 1.0 / this.audioContext['bufferLength'];
        var x = 0;
        let max = 0;

        for (var i = 0; i < this.audioContext['bufferLength']; i++) {
          var v = this.audioContext['dataArray'][i] / 128.0;
          if (((v - 0.93) * 100) >= 20 && (Date.now() / 1000) - this.timer_audio_high > 60 && this.props.processo.simulado == false
        && this.props.andamento==true
        ) {
            console.log('ta alto')
            this.timer_audio_high = Date.now() / 1000;
            this.salvar_log_screen_extended('audio_high');
          }
          // else{
          //     bar1[0].hidden=true;
          //     bar2[0].hidden=true;
          //     bar3[0].hidden=true;
          // }
          // if((v-1)>0)
          //     bar_peer.style.clipPath='circle(40%)';
          // else
          //     bar_peer.style.clipPath='circle(30%)';



        }
        setTimeout(() => {
          this.draw_peer()
        }, 300);
      }
      else {
        this.first = true;
      }
    }
    catch (e) {
      console.log(e)
    }


  };


  async componentDidMount() {
    this.props.changeState({ loading_home: true });
    this.event_storage = ((e) => {
      console.log(e)
      if (e.key == "openpages") {
        // Listen if anybody else is opening the same page!
        localStorage.page_available = Date.now();
      }
      if (e.key == "page_available") {
        this.setState({ other_window: true });
      }
    }).bind(this)
    localStorage.openpages = Date.now();
    window.addEventListener('storage', this.event_storage, false);
    try {
      this.myWorker = new SharedWorker('check.js');
      this.myWorker.port.start();
      this.myWorker.port.postMessage(this.user_id);

      this.myWorker.port.onmessage = e => {
        // console.log(e.data);
        // if (e.data == true) {
        //   this.get_user();
        // }
        // else {
        //   this.setState({ other_window: true });
        // }
      };
      this.time_get_user = setTimeout(() => {
        this.get_user();
      }, 1000);

    }
    catch (e) {
      this.setState({ mobile: true });
      this.time_get_user = setTimeout(() => {
        this.get_user();
      }, 1000);

    }

    // if ('getScreenDetails' in window) {
    //   // The Multi-Screen Window Placement API is supported.
    //   this.screenCheck=((event) => {
    //     // Handle screens change.
    //     console.log(event)
    //   }).bind(this);
    //   this.screenDetails = await window.getScreenDetails();
    //   this.screenDetails.addEventListener('screenschange', this.screenCheck);
    // }




  }

  salvar_log_screen_extended(screen_extended) {
    let event_m = '';

    if (screen_extended == true) {
      event_m = 'screen_extended_active';

    }
    else if (screen_extended == false) {
      event_m = 'screen_extended_deactive';

    }
    else {
      event_m = screen_extended
    }

    fetch(`${URL}api/salvar_log?screen=${event_m}&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',

        'Authorization': `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token');
          // this.setState({redirect:true,path:'/'});
          this.props.logout();
        }
        else {

        }
      } catch (err) {
        console.log(err);
      }

    })
      .catch((err) => {
        console.log(err);
      });
  }

  ping_log(list_pings) {
    try{

    if(this.props.processo==null){
      return;
    }

    fetch(`${URL}api/ping_log`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',

        'Authorization': `Bearer ${this.props.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        logs: list_pings
      })
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token');
          // this.setState({redirect:true,path:'/'});
          this.props.logout();
        }
        else {
          localStorage.removeItem('list_pings');
          this.pingInternal = setTimeout(() => {
            this.ping();
          }, this.props.processo?.save_timer_pre_exam);
        }

      } catch (err) {
        this.pingInternal = setTimeout(() => {
          this.ping_log(list_pings);
        }, this.props.processo?.save_timer_pre_exam);
      }


    })
      .catch((err) => {
        this.pingInternal = setTimeout(() => {
          this.ping_log(list_pings);
        }, this.props.processo?.save_timer_pre_exam);
      });
    }catch(e){
      
    }
  }

  ping() {
    try{
    if(this.props.processo==null){
      return;
    }

    let start = Date.now();
    let list_pings = localStorage.getItem('list_pings');
    if (list_pings == null) {
      list_pings = [];
    }
    else {
      list_pings = JSON.parse(list_pings);
    }

    fetch(`${URL}favicon.ico`, {
      method: 'GET',
      headers: {
      },
      mode: 'no-cors'

    }).then(async (responseLog) => {
      try {
        this.end = Date.now() - start;
        if (this.end >= 100) {
          document.getElementById('ping_time').style.color = 'darkgoldenrod';
        }
        else {
          document.getElementById('ping_time').style.color = 'green';
        }
        document.getElementById('ping_time').innerText = this.end + ' ms';


        list_pings.push({ datetime: moment().tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss'), ms: this.end });
        localStorage.setItem('list_pings', JSON.stringify(list_pings));
        if (list_pings.length > 10) {
          this.ping_log(list_pings);
        }
        else {
          this.pingInternal = setTimeout(() => {
            this.ping();
          }, this.props.processo?.save_timer_pre_exam);
        }
      } catch (err) {
        console.log(err);
        localStorage.removeItem('list_pings');


        this.pingInternal = setTimeout(() => {
          this.ping();
        }, this.props.processo?.save_timer_pre_exam);
      }


    })
      .catch((err) => {
        console.log(err);
        document.getElementById('ping_time').style.color = 'red';
        document.getElementById('ping_time').innerText = '-- ms';
        list_pings.push({ datetime: moment().tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss'), ms: null });
        localStorage.setItem('list_pings', JSON.stringify(list_pings));
        console.log(list_pings)
        this.pingInternal = setTimeout(() => {
          this.ping();
        }, this.props.processo?.save_timer_pre_exam);
      });
    } catch(e){

    }
  }

  async get_user() {
    const MODEL_URL = '/models'

    // await faceapi.loadSsdMobilenetv1Model(MODEL_URL)
    // await faceapi.loadFaceLandmarkTinyModel(MODEL_URL)
    // await faceapi.loadFaceRecognitionModel(MODEL_URL)

    /*await faceapi.loadTinyFaceDetectorModel(MODEL_URL);
    await faceapi.loadFaceLandmarkTinyModel(MODEL_URL);
    await faceapi.loadFaceLandmarkModel(MODEL_URL);
    await faceapi.loadFaceRecognitionModel(MODEL_URL);
    await faceapi.loadFaceExpressionModel(MODEL_URL);
    await faceapi.loadAgeGenderModel(MODEL_URL);
    await faceapi.loadFaceDetectionModel(MODEL_URL);

    const options = new faceapi.TinyFaceDetectorOptions({
      inputSize: 128,
      scoreThreshold: 0.3
    });
    console.log('leu models');
    let fullFaceDescriptions = await faceapi.detectAllFaces(this.imageRef.current, options).withFaceLandmarks(true).withFaceDescriptors();
    console.log(this.props.token);*/
    fetch(`${URL}api/get_user/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',

        'Authorization': `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token');
          // this.setState({redirect:true,path:'/'});
          this.props.logout();

          return;
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);

          // this.props.mudarLoadingHome(false);
          this.time_get_user = setTimeout(() => {
            this.get_user();
          }, 5000);
        }
        else {
          // this.props.mudarFaculdade(resp.faculdade);
          // if (resp.processo != null) {
          // this.props.mudarProcesso(resp.processo);

          // this.props.mudarObjectWorker(this.object_worker);
          if (resp.user.fotos?.length > 0 && true == false) {
            if (resp.processo != null) {
              localStorage.setItem('processo_id', resp.processo.id);
            }
            this.canvas.current.height = this.imageRef.current.height;
            this.canvas.current.width = this.imageRef.current.width;
            this.canvas.current.getContext('2d').drawImage(this.imageRef.current, 0, 0, this.imageRef.current.width, this.imageRef.current.height);
            let imgData2 = this.canvas.current.getContext('2d').getImageData(0, 0, this.imageRef.current.width, this.imageRef.current.height);
            const { height, width, data } = imgData2;
            this.object_worker = new Worker('cocossd.js');
            this.object_worker.postMessage({ image: data, width: width, height: height, first: true });

            let fotos = resp.user.fotos;
            let fotos_final = [];
            for (let i = 0; i < fotos.length; i++) {
              // let img=await faceapi.fetchImage('https://digivest.enterscience.com.br/storage/alunos/Matheus_Carreira_Andrade_23/foto_5f7105a40a035.jpeg');
              // let img = await faceapi.fetchImage(URL + 'storage/' + fotos[i].foto);
              // let img = await fetch(URL + 'storage/' + fotos[i].foto,{mode:'no-cors'});

              // this.canvas.current.height = img.height;
              // this.canvas.current.width = img.width;
              // this.canvas.current.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
              // let imgData2 = this.canvas.current.getContext('2d').getImageData(0, 0, img.width, img.height);
              // let { height, width, data } = imgData2;

              // fotos_final.push({
              //   label: fotos[i].foto,
              //   data: { height, width, data }
              // });
            }

            // this.props.mudarUser(resp.user);
            // this.props.mudarFotosUsuario(fotos_final);
            this.worker = new SharedWorker('face_shared.js');
            this.worker.port.start();

            // this.props.mudarWorker(this.worker);
            this.props.changeState({
              worker: this.worker,
              faculdade: resp.faculdade,
              processo: resp.processo,
              user: resp.user,
              sala: resp.sala,
              loading_home: false,
              fotos_usuario: fotos_final,
              object_worker: this.object_worker,
              processo_resultado: resp.processo_resultado
            });


            //fazer que nem fez com a foto do video, transformar para canvas e pegar os dados da imagem
            // let img=await faceapi.fetchImage('https://digivest.enterscience.com.br/storage/alunos/Matheus_Carreira_Andrade_23/foto_5f7105a40a035.jpeg');
            console.log(fotos_final);
            this.canvas.current.toBlob((e) => {
              this.worker.port.postMessage({ image: data, width: width, height: height, fotos: fotos_final, first: true });

            }, 'image/jpeg');
          }
          else {
            // this.props.mudarUser(resp.user);
            this.props.changeState({
              faculdade: resp.faculdade,
              processo: resp.processo,
              user: resp.user,
              sala: resp.sala,
              loading_home: false,
              processo_resultado: resp.processo_resultado
            });
          }
          clearInterval(this.screenExtended);
          this.screenExtended = setInterval(() => {
            // console.log(window.screen.isExtended)
            let extended = DEBUG?false: window?.screen?.isExtended;
            if (extended == undefined || extended == null) {
              extended = false;
            }
            if (this.props.processo != null && this.props.processo.screen_extended_retrict == true && extended != this.props.screenExtended && (this.props.screenExtended == false
              || (this.props.screenExtended == true && document.getElementById('container-s12319dasa') != null)
            )) {
              // console.log( document.getElementById('container-s12319dasa'));
              // console.log(this.state.screenExtended)
              
             


              this.props.changeState({ screenExtended: extended });
              if (this.props.andamento == true && this.props.processo.simulado == false) {
                this.salvar_log_screen_extended(extended)
                this.props.history.push({
                  pathname: '/',
                  search:  '?error=Não é permitido o uso de duas ou mais telas ao acessar a avaliação' 
                });
              }
            }
            else if (this.props.processo != null && this.props.processo.screen_extended_retrict == false && this.props.screenExtended == true) {
              this.props.changeState({ screenExtended: false });
            }

            if( document.getElementById('container-s12319dasa')!=null){
              console.log('setting ')
              document.getElementById('container-s12319dasa').style.cssText="z-index:1000000000000!important;width:unset!important;height:unset!important;position:fixed!important;background:white!important;top:0!important;left:0!important;right:0!important;bottom:0!important;";
              document.getElementById('container-s12319dasa').hidden=false;
            }
            // if(document.getElementById('container-s12319dasa')==null){
            //   console.log('DELETOU');
            // }
          }, 5000);
          let user_id = localStorage.getItem('user_id_ping');
          if (user_id != resp.user?.id) {
            localStorage.removeItem('list_pings');
          }
          localStorage.setItem('user_id_ping', resp.user.id)


          // if (this.props.andamento == true && resp.processo.simulado == false) {
          //   this.pingInternal = setTimeout(() => {

          //     this.ping();
          //   }, this.props.processo.save_timer_pre_exam);
          // }

          // }
          // if (resp.sala != null) {
          // this.props.mudarSala(resp.sala);
          // // }
          // this.props.mudarLoadingHome(false);
          if(resp.processos?.length>1){
            if(this.state.processos.length==0){
              this.setState({processos:resp.processos,show_proccess:true});
            }
          }
        }




      } catch (err) {
        console.log(err);
        // this.props.mudarLoadingHome(false);
        this.time_get_user = setTimeout(() => {
          this.get_user();
        }, 5000);

      }

    })
      .catch((err) => {
        console.log(err);
        // this.props.mudarLoadingHome(false);
        this.time_get_user = setTimeout(() => {
          this.get_user();
        }, 5000);
      });
  }

  select_proccess(processo_id) {
    
    fetch(`${URL}api/select_proccess`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',

        'Authorization': `Bearer ${this.props.token}`,
        'Content-Type': 'application/json',
      },
      body:JSON.stringify({
        processo_id:processo_id
      })
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token');
          // this.setState({redirect:true,path:'/'});
          this.props.logout();
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);

          // this.props.mudarLoadingHome(false);
          this.time_select_proccess = setTimeout(() => {
            this.select_proccess(processo_id);
          }, 5000);
        }
        else {
          if(resp.status==false){
            this.setState({processos:[]},()=>{
              this.get_user();
            });
          }
          else{
            this.get_user();
          }
        }
      } catch (err) {
        console.log(err);
        this.time_select_proccess = setTimeout(() => {
          this.select_proccess(processo_id);
        }, 5000);
      }

    })
      .catch((err) => {
        console.log(err);
        this.time_select_proccess = setTimeout(() => {
          this.select_proccess(processo_id);
        }, 5000);
      });
  }

  salvar_log_permission(msg) {
    fetch(`${URL}api/salvar_log?screen=permission&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}&msg=${msg}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',

        'Authorization': `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token');
          // this.setState({redirect:true,path:'/'});
          this.props.logout();
        }
        else {

        }
      } catch (err) {
        console.log(err);

      }

    })
      .catch((err) => {
        console.log(err);

      });
  }

  componentDidUpdate(props) {
    if (this.props.stream != null && this.videoRef.current != null && (props.stream == null || this.props.stream.id != props.stream.id)) {
      this.videoRef.current.srcObject = this.props.stream
      try {
        this.audioContext['context'] = new AudioContext();
        this.audioContext['analyser'] = this.audioContext['context'].createAnalyser();
        this.audioContext['frequencyData'] = null;
        this.audioContext['source'] = this.audioContext['context'].createMediaStreamSource(this.props.stream);
        this.audioContext['source'].connect(this.audioContext['analyser']);
        this.audioContext['gainNode'] = this.audioContext['context'].createGain();
        this.audioContext['gainNode'].gain.value = 0.1; // setting it to 10%
        this.audioContext['gainNode'].connect(this.audioContext['context'].destination);
        // analyser.connect(context.destination);

        this.audioContext['context'].resume()
        this.audioContext['analyser'].fftSize = 32;
        this.audioContext['bufferLength'] = this.audioContext['analyser'].frequencyBinCount;
        this.audioContext['dataArray'] = new Uint8Array(this.audioContext['bufferLength']);
        this.audioContext['analyser'].getByteTimeDomainData(this.audioContext['dataArray']);

        if (this.first == true) {
          this.first = false;
          this.draw_peer();
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (this.props.stream_screen != null && this.videoRef.current != null && (props.stream_screen == null || this.props.stream_screen.id != props.stream_screen.id)) {
      this.screenRef.current.srcObject = this.props.stream_screen
    }
    if (this.props.stream != null && props.stream != this.props.stream) {
      console.log('inactive stream')
      this.props.stream.oninactive = () => {
        console.log('inactive2')

        this.stream_screen = null;
        if (this.unmont_ == false) {
          // this.setState({ loading_camera: false, msg: 'Compartilhe a tela cheia para avançar', show_screen_sharing: true, show_screen: false, show_aceitar: false });
        }
        else {
        }
        if(this.props.show_call==true){
          this.props.socket.emit('exit_call', { aluno_id: this.props.user.id, processo_id: this.props.processo.id, id: this.props.user.id })
        }
        if (this.props.stream_screen != null) {
          this.props.stream_screen.getVideoTracks()[0].onended = function () { };
          this.props.stream_screen.getTracks().map((track) => track.stop());
        }

        this.props.changeState({ stream: null, stream_screen: null })
        // if(this.props.processo?.screen_sharing==true){

        // }
        this.salvar_log_permission('Cancelou permissão de câmera');

        this.props.history.push({
          pathname: '/',
          search: this.props.processo.screen_sharing == true ? '?error=Compartilhamento de Vídeo/Áudio ou Tela foi cancelado pelo candidato. Compartilhe novamente para realizar a avaliação' : '?error=Compartilhamento de Vídeo/Áudio foi cancelado pelo candidato. Compartilhe novamente para realizar a avaliação'
        });
        console.log('teste2')
      }
    }
    if (this.props.stream_screen != null && props.stream_screen != this.props.stream_screen) {
      console.log('inactive screen')

      this.props.stream_screen.getVideoTracks()[0].onended = () => {
        console.log('inactive3')
        if(this.props.show_call==true){
          this.props.socket.emit('exit_call', { aluno_id: this.props.user.id, processo_id: this.props.processo.id, id: this.props.user.id })
        }
        if (this.props.stream != null) {
          this.props.stream.oninactive = function () { };
          this.props.stream.getTracks().map((track) => track.stop());
        }

        
        // if (this.unmont_ == false) {
        //   this.setState({ loading_camera: false, msg: 'Compartilhe a tela cheia para avançar', show_screen_sharing: true, show_screen: false, show_aceitar: false });
        // }
        // else {
        // }
        let active = this.props.stream?.active;
        this.props.changeState({ stream: null, stream_screen: null })
        this.salvar_log_permission('Cancelou permissão de compartilhamento de tela');

        this.props.history.push({
          pathname: '/',
          search: '?error=Compartilhamento de Vídeo/Áudio ou Tela foi cancelado pelo candidato. Compartilhe novamente para realizar a avaliação'
        });
      }


      console.log('teste3')
    }

    if (this.props.processo!=null && this.props.andamento != props.andamento && this.props.andamento == true && this.props.processo.simulado == false) {
      clearTimeout(this.pingInternal);
        this.pingInternal = setTimeout(() => {
        this.ping();
      }, this.props.processo?.save_timer_pre_exam);
    }
  }


  componentWillUnmount() {
    clearTimeout(this.time_get_user);
    clearTimeout(this.time_select_proccess);

    if (this.videoRef.current != null) {
      this.videoRef.current.srcObject = null;
    }
    if (this.screenRef.current != null) {
      this.screenRef.current.srcObject = null;
    }
    if (this.worker != null) {
      // this.worker.terminate();
      this.props.changeState({ worker: null });
    }
    clearInterval(this.screenExtended)
    clearTimeout(this.pingInternal)

    if (this.event_storage != null) {
      window.removeEventListener('storage', this.event_storage, false);
    }
    // if(this.screenDetails!=null){
    //   this.screenDetails.removeEventListener('screenschange', this.screenCheck);
    // }
  }

  render() {
    console.log(this.props.processo)
    return (
      <div style={{
        background: '#f8f8fa',
        minHeight: '100vh'
      }}>
        {this.state.other_window == false && <div>

          {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}
          <canvas ref={this.canvas} id="background-canvas" style={{ position: 'absolute', top: '-500px', left: '-500px' }}></canvas>

          <img ref={this.imageRef} hidden />
          <div id="wrapper">
            {(this.props.show_call==false || (this.props.show_call==true && this.props.user.accessibility==true && this.props.user.type_supervisor!='none' && this.props.processo.is_interview==false)) && <Header />}
            {this.props.loading_home == false && (this.props.processo == null || !(this.props.processo.mobile == false && this.state.mobile == true)) && <div className={(this.props.processo == null || this.props.processo.mobile == undefined || this.props.processo.mobile == false) ? "d-none d-sm-block" : "d-block d-sm-block"}>
              <div id="content-page" className="content-page" style={{ marginLeft: 0 }}>
                <div className="content">
                  {this.props.stream != null && this.props.processo != null && (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && this.props.processo_resultado != null && this.props.processo_resultado.status != 'F' && <div hidden={this.props.show_call==true && (this.props.user.accessibility==false || this.props.user.type_supervisor=='none' || this.props.processo.is_interview==true)}>
                    <div className='d-sm-block d-none' style={{ position: 'fixed', top: '170px', right: '50px', zIndex: 200 }}>
                      <button onClick={() => {
                        this.setState({hide:!this.state.hide})
                      }} className="btn btn-secondary btn-sm" data-id="content_question" data-toggle="modal"
                        data-target="#change_camera">
                        {this.state.hide==true?'Mostrar' :'Ocultar'}
                      </button>
                      {/*<button hidden={this.state.hide==true} onClick={() => this.props.changeState({ change_cam_modal: true })} className="btn btn-primary btn-sm" data-id="content_question" data-toggle="modal"
                        data-target="#change_camera">
                        Alterar
                      </button>*/}
                    </div>
                    <div hidden={this.state.hide==true} className='d-sm-block d-none' style={{ position: 'fixed', top: '100px', right: '50px', zIndex: 100 }}>
                      <div hidden={this.state.hide==true}>
                        <video src={this.props.stream} autoPlay style={{ height: '100px' }} id="video_oficial" ref={this.videoRef} muted>
                        </video>
                        {this.props.processo.screen_sharing == true && <video src={this.props.stream_screen} autoPlay style={{ height: '100px' }} id="video_oficial_screen" ref={this.screenRef} muted>
                        </video>}
                      </div>
                      {/* <ChangeCamModal /> */}

                    </div>
                    <ConexaoProcesso />

                  </div>}

                  <div className="container-fluid">
                    <div className="page-title-box">
                      <div className="row align-items-center">
                        <div className="col-sm-12">
                          <h2 style={{ color: 'black' }}>{this.props.faculdade.name}</h2>
                        </div>
                      </div>
                    </div>
                    {/* {this.props.processo != null &&
                   } */}
                    <Switch>
                      {this.props.processo != null && <>
                        <Route exact path={`${this.props.match.url}`} render={(props2) => <Termos selectProccess={(processo_id)=>{
                          this.props.changeState({ loading_home: true });
                          this.setState({show_proccess:false});
                          this.select_proccess(processo_id);
                        }} setShowProccess={(e)=>this.setState({show_proccess:e})}  processos={this.state.processos} show_proccess={this.state.show_proccess} {...props2} />} />
                        <Route exact path={`${this.props.match.url}processo`} render={(props2) => { return (<ProcessoUser {...props2} />) }} />
                      </>}


                      {this.props.processo == null && <Route exact path={`${this.props.match.url}`} component={(props2) => <NotProcesso {...props2} />} />}

                      <OtherRoute path="*" path_name='/' />

                    </Switch>
                    {/* { this.props.children } */}
                    {/* {this.props.processo == null &&
                    <NotProcesso />} */}
                  </div>
                </div>
              </div>
            </div>}
            {this.props.loading_home == true && <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
              <div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
                <h4 style={{ textAlign: 'center' }}>Carregando dados. Por favor, aguarde.</h4>
              </div>
            </div>}

          </div>
          {(this.props.processo == null || this.props.processo.mobile == undefined || this.props.processo.mobile == false) && <div className={this.props.processo != null && (this.props.processo.mobile == false && this.state.mobile == true) ? "d-flex" : "d-flex d-sm-none"} style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
            <div>

              <p style={{ textAlign: 'center', fontSize: '1.2rem' }}>Deslogue e acesse pelo computador para poder visualizar nosso conteúdo
              </p>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <a className="btn btn-success btn-lg" onClick={this.logout.bind(this)} style={{ width: '80%', color: 'white' }}><i
                  className="mdi mdi-power " style={{ color: 'white' }}></i> Sair</a>
              </div>
            </div>

          </div>}

          {(this.props.screenExtended == true) && <div id='container-s12319dasa' className="d-flex justify-content-center align-items-center" style={{ zIndex: 1000000000000, position: 'fixed', bottom: 0, left: 0, right: 0, top: 0, background: 'white' }}>
            <div style={{ margin: 20 }}>
              <h4 style={{ textAlign: 'center', color: 'red' }}>{'Não é permitido usar duas telas durante a avaliação'}</h4>
            </div>
          </div>}
        </div>}

        {(this.state.other_window == true) && <div className="d-flex justify-content-center align-items-center" style={{ zIndex: 1000000000000, position: 'fixed', bottom: 0, left: 0, right: 0, top: 0, background: 'white' }}>
          <div style={{ margin: 20 }}>
            <h4 style={{ textAlign: 'center', color: 'red' }}>{'Você já possui uma outra janela deste sistema aberta. Feche-a e atualize esta página para continuar.'}</h4>
          </div>
        </div>}

        <div id='ping_time' style={{ position: 'fixed', bottom: 10, left: 10, fontSize: 13, color: 'green' }}>

        </div>

      </div >

    );
  }


}

const mapsStateToProps = (state) => (
  {
    faculdade: state.AppReducer.faculdade,
    token: state.AppReducer.token,
    loading_home: state.AppReducer.loading_home,
    stream: state.AppReducer.stream,
    stream_screen: state.AppReducer.stream_screen,

    processo: state.AppReducer.processo,
    user: state.AppReducer.user,

    processo_resultado: state.AppReducer.processo_resultado,
    screenExtended: state.AppReducer.screenExtended,
    andamento: state.AppReducer.andamento,
    show_call: state.AppReducer.show_call,
    socket: state.AppReducer.socket,

  }
);

export default connect(mapsStateToProps, { changeState, logout })(Home);
